<template>
    <div class="flex">
        <div class="img_list flex flex-v flex-center" v-for="(item,idx) in fileList" :key="idx">
            <!-- <video v-if="fileList.length>0"
                style="width:100%;height:100%"
                :src="fileList[0].url">
            </video>
            <div class="masking flex flex-v flex-center">
                <div>
                    <el-button @click="del(idx)" type="text">删除</el-button>
                    <el-button @click="videoView()" type="text">预览</el-button>
                </div>
            </div> -->
            <div>
                <div>视频已上传</div>
                <el-button @click="del(idx)" type="text">删除</el-button>
                <el-button @click="videoView()" type="text">预览</el-button>
            </div>
        </div>
        <div class="flex" style="position:relative;">
            <el-upload v-if="fileList<1" class="avatar-uploader" 
                :show-file-list="false" 
                :limit="1" 
                action="xx" 
                list-type="text" 
                :accept="uploadAccept" 
                :before-upload="beforeUpload" 
                :http-request="handleVideoSuccess" 
                :on-remove="removeImg" 
                :file-list="fileList">
                <div class="avatar-uploader-icon flex flex-v flex-pack-center">
                    <i v-if="fileList.length<1" class="el-icon-plus"></i>
                    <span v-if="fileList.length<1" style="padding-top: 10px;">上传视频</span>
                </div>
                <span class="fileLength">（{{fileList.length}}）</span>
            </el-upload>
            <div class="hint">
                <div>
                    1.视频大小建议不超过30MB;
                    2.只支持mp4、m3u8格式的视频;
                </div>
                <div v-if="newLimit" class="imgLength">{{(fileList || '').length}}/{{newLimit}}</div>
            </div>
        </div>
        <!-- <div style="position:relative;">
            <el-upload class="avatar-uploader"
                        action="xx"
                        list-type="text" 
                        :on-progress="uploadVideoProcess"
                        :before-upload="beforeUpload"
                        :on-success="handleVideoSuccess"
                        :accept="uploadAccept"
                        :show-file-list="true"
                        :on-remove="removeImg" 
                        :file-list="fileList">
                <video v-if="videoForm.showVideoPath !='' && !videoFlag"
                        :src="videoForm.showVideoPath"
                        class="avatar video-avatar"
                        controls="controls">
                    您的浏览器不支持视频播放
                </video>
                <div v-else-if="videoForm.showVideoPath =='' && !videoFlag" 
                    class="avatar-uploader-icon flex flex-v flex-pack-center" style="opacity:1;">
                    <i class="el-icon-plus"></i>
                    <span style="padding-top:10px">上传视频</span>
                </div>
                <el-progress v-if="videoFlag == true"
                    type="circle"
                    v-bind:percentage="videoUploadPercent"
                    style="margin-top:7px;"></el-progress>
            </el-upload>
        </div> -->

        <!-- 视频预览弹窗 -->
        <el-dialog width="500px" :append-to-body="true" :modal-append-to-body="false" :title="popTitle" :visible.sync="popVisible">
            <div class="video-view">
                <video v-if="fileList.length>0"
                    style="width:100%;"
                    :src="fileList[0].url"
                    controls="controls">
                    您的浏览器不支持视频播放
                </video>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    import { IMG_URL_PRE } from '@/config'
    import { uploadFile } from '@/service/common';
    export default {
        model: {
            prop: 'imgs',
            event: 'change',
        },
        props: ['imgs','prefix'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                popTitle:null,
                popVisible:false,
                uploadAccept: 'video/mp4,video/m3u8',
                fileList:[],
                newLimit: 1,
                videoFlag: false,
                //是否显示进度条
                videoUploadPercent: "",
                //进度条的进度
                isShowUploadVideo: false,
                //显示上传按钮
                videoForm: {
                    showVideoPath: ''
                }
            }
        },
        created() {
            if(this.imgs && this.imgs.length>0){
                for(let img of this.imgs){
                    if (img.indexOf(this.imgUrl)==-1) {
                        this.fileList.push({url:this.imgUrl+img});
                    } else {
                        this.fileList.push({url:img});
                    }
                    
                }
            }
            // console.log(this.imgs);
        },
        methods: {
            del(index) {
                this.$confirm('确认要删除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    this.fileList.splice(index,1);
                    this.onChange();
                });
            },
            onChange(){
                let _imgs=this.fileList.reduce((pre,cur)=>{
                    let url = cur.url.replace(this.imgUrl, "");
                    pre.push(url);
                    return pre;
                },[]);
                this.$emit('change', _imgs)
            },
            //上传前回调
            beforeUpload(file) {
                console.log(file);
                let testmsg = file.name.substring(file.name.lastIndexOf('.')+1);
                if (['video/mp4','video/m3u8'].indexOf(file.type) == -1) {
                    this.$message.error("请上传mp4、m3u8格式的视频");
                    return false;
                }
                let fileSize = file.size / 1024 / 1024 < 30;
                if (!fileSize) {
                    this.$message.error("视频大小不能超过30MB");
                    return false;
                }
                // 获取视频时长
                // let url = URL.createObjectURL(file);
                // let audioElement = new Audio(url);
                // let duration = null;
                // let _this = this;
                // audioElement.onloadedmetadata = function(event) {
                //     duration = audioElement.duration;
                //     console.log(duration);
                //     if (duration && duration>10) {
                //         _this.$message.error("视频大小不能超过60秒");
                //         return false; 
                //     }
                // };
            },
            //进度条
            uploadVideoProcess(event, file, fileList) {
                this.videoFlag = true;
                this.videoUploadPercent = file.percentage.toFixed(0) * 1;
            },
            //上传成功回调
            handleVideoSuccess(item) {
                // this.isShowUploadVideo = true;
                // this.videoFlag = false;
                // this.videoUploadPercent = 0;
                const _loading = this.$loading({
                    lock: true,
                    text: "拼命上传中....请耐心等待",
                });
                uploadFile(item.file,this.prefix, {
                        onUploadProgress: event => {
                        item.file.percent = (event.loaded / event.total) * 100;
                        item.onProgress(item.file);
                        }
                    }).then(url => {
                    this.fileList.push({
                        status: "success",
                        uid: new Date().getTime(),
                        url: IMG_URL_PRE + url
                    });
                    this.onChange();
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            removeImg(file, fileList){
                this.fileList=fileList;
                this.onChange();
            },
            videoView() {
                this.popTitle = "预览";
                this.popVisible = true;
            },
        },
    }
</script>
<style scoped>
    .video-view {
        width:300px;
        margin: 0 auto ;
    }
    .img_list {
        width: 148px;
        height: 148px;
        position: relative;
        cursor: pointer;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        transition:all 1s linear;
    }
    .img_list:hover .masking {
        opacity: 1;
    }
    .masking {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .avatar-uploader {
        width: 148px;
        height: 148px;
        display: block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        font-size: 0;
    }
    .avatar-uploader:hover {
        border-color: #24B1EB;
    }
    .avatar-uploader-icon {
        color: #24B1EB;
        font-size: 24px;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
    }
    .avatar-uploader-icon span {
        line-height: 1;
        color: #24B1EB;
        font-size: 14px;
        font-weight: 500;
    }
    .baseinfo-avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
    .hint {
        width: 165px;
        font-size: 12px;
        color: #808080;
        line-height: 20px;
        padding-left: 16px;
    }
    .imgLength {
        font-size: 14px;
        font-weight: 500;
        color: #808080;
        line-height: 20px;
        width: 50px;
        padding: 68px 0 0 5px;
        /* position: absolute;
            right: 0;
            bottom: 0; */
    }
</style>